<script setup lang="ts">
</script>

<template>
  <div>
    <NuxtLoadingIndicator />
    <NuxtLayout name="dashboard" cta="Contact us for a 1:1 consultation regarding all things related to market makers & liquidity!">
      <template #header>
        <div class="flex gap-2 items-center">
          <img src="/icons/market-maker-monitoring.svg" alt="Token Designer Icon" width="32" height="32">
          <div class="text-sm font-semibold flex-shrink-0">
            Market Maker Monitoring
          </div>
        </div>
      </template>
      <template v-if="$slots.sidebar" #sidebar>
        <slot name="sidebar" />
      </template>
      <slot />
    </NuxtLayout>
  </div>
</template>
